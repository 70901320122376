import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/sambrajyam-reddy.jpg";
  const fullName = "Sambrajyam Reddy";
  const zoomUrl = "";
  const zoomPhoneNumber = "";
  const zoomMeetingId = "";
  const birthDate = "October 1st, 1940";
  const deathDate = "November 6th, 2020";
  const memorialDate = "Sunday November 22nd, 2020";
  const memorialTime = "11am EST";
  const inviteText = "";
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/raji.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      recordingEmbedUrl={recordingEmbedUrl}
      customColumnSpacing="2fr 3fr"
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    ></StoryPage>
  );
};

export default ThisPage;
